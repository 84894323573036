import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/DocsLayout.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Alert = makeShortcode("Alert");
const AlertTitle = makeShortcode("AlertTitle");
const Link = makeShortcode("Link");
const CodeBlock = makeShortcode("CodeBlock");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`One could write several telephone books about the innards of X.509, asn.1 and the vast topics of infosec, netsec, and public key cryptography that are implicated in the design of a PKI. It's daunting. So let's begin at the beginning, with a CA server and a TLS-enabled "Hello World" application.`}</p>
    <p>{`In this guide we will:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#initialize-your-certificate-authority"
        }}>{`Initialize your certificate authority`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#run-your-certificate-authority"
        }}>{`Run your certificate authority`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#example-run-a-local-web-server-using-tls"
        }}>{`Example: Run A Local Web Server Using TLS`}</a></li>
    </ul>
    <h2>{`Requirements`}</h2>
    <p>{`You will need `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli"
      }}><inlineCode parentName="a">{`step`}</inlineCode></a>{` and `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-ca"
      }}><inlineCode parentName="a">{`step-ca`}</inlineCode></a>{` installed on your system.`}</p>
    <h2>{`Initialize your certificate authority`}</h2>
    <p>{`The certificate authority is what you’ll be using to issue and sign certificates, knowing that you can trust anything using a certificate signed by the root certificate.`}</p>
    <p>{`Run the command `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/ca/init"
      }}><inlineCode parentName="a">{`step ca init`}</inlineCode></a>{` in a terminal to configure your CA. You'll be asked about your project, DNS setup, etc.`}</p>
    <p>{`The `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/ca/init"
      }}><inlineCode parentName="a">{`step ca init`}</inlineCode></a>{` command has a lot of flags.
Here are some of the most popular options:`}</p>
    <ul>
      <li parentName="ul">{`Pass `}<inlineCode parentName="li">{`--ssh`}</inlineCode>{` if you want to use SSH certificates (in addition to X.509)`}</li>
      <li parentName="ul">{`Pass separate `}<inlineCode parentName="li">{`--password-file`}</inlineCode>{` and `}<inlineCode parentName="li">{`--provisioner-password-file`}</inlineCode>{` flags to set different signing CA and provisioner passwords.`}</li>
      <li parentName="ul">{`Pass `}<inlineCode parentName="li">{`--helm`}</inlineCode>{` to generate Helm YAML for use with `}<a parentName="li" {...{
          "href": "https://github.com/smallstep/helm-charts/tree/master/step-certificates"
        }}>{`our Helm chart`}</a></li>
      <li parentName="ul">{`Pass `}<inlineCode parentName="li">{`--acme`}</inlineCode>{` to create an `}<a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/step-ca/provisioners/#acme"
        }}>{`ACME provisioner`}</a></li>
      <li parentName="ul">{`Pass `}<inlineCode parentName="li">{`--remote-management`}</inlineCode>{` to enable `}<a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/step-ca/provisioners/#remote-provisioner-management"
        }}>{`Remote Provisioner Management`}</a></li>
    </ul>
    <Alert severity="success" mdxType="Alert">
  <AlertTitle mdxType="AlertTitle">Don&apos;t want to run your own CA?</AlertTitle>
  <div>
    To get up and running quickly, consider creating a{' '}
    <Link external href="https://smallstep.com/signup?product=cm" mdxType="Link">
      free hosted smallstep Certificate Manager authority
    </Link>
    .
  </div>
    </Alert>
    <p>{`Note that one of the prompts is about the default provisioner, but for now an email address works fine as an identifier.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ step ca init

✔ What would you like to name your new PKI? (e.g. Smallstep): Example Inc.
✔ What DNS names or IP addresses would you like to add to your new CA? (e.g. ca.smallstep.com[,1.1.1.1,etc.]): localhost
✔ What address will your new CA listen at? (e.g. :443): 127.0.0.1:8443
✔ What would you like to name the first provisioner for your new CA? (e.g. you@smallstep.com): bob@example.com
✔ What do you want your password to be? [leave empty and we will generate one]: abc123

Generating root certificate...
all done!

Generating intermediate certificate...
all done!

✔ Root certificate: /Users/bob/.step/certs/root_ca.crt
✔ Root private key: /Users/bob/.step/secrets/root_ca_key
✔ Root fingerprint: 702a094e239c9eec6f0dcd0a5f65e595bf7ed6614012825c5fe3d1ae1b2fd6ee
✔ Intermediate certificate: /Users/bob/.step/certs/intermediate_ca.crt
✔ Intermediate private key: /Users/bob/.step/secrets/intermediate_ca_key
✔ Default configuration: /Users/bob/.step/config/defaults.json
✔ Certificate Authority configuration: /Users/bob/.step/config/ca.json

Your PKI is ready to go.
`}</code></pre>
    <p><strong parentName="p">{`Make a note of the root fingerprint!`}</strong>{` You'll need it to establish trust with your CA from other environments or hosts.`}</p>
    <h2>{`Run your certificate authority`}</h2>
    <p>{`Run your certificate authority and pass it the configuration file you just generated.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ step-ca $(step path)/config/ca.json

Please enter the password to decrypt /Users/bob/.step/secrets/intermediate_ca_key: abc123

2019/02/18 13:28:58 Serving HTTPS on 127.0.0.1:8443 ...
`}</code></pre>
    <h3>{`Accessing your Certificate Authority`}</h3>
    <h4>{`Accessing your CA`}</h4>
    <p>{`You can use `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/ca"
      }}><inlineCode parentName="a">{`step ca`}</inlineCode></a>{` and `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/ssh"
      }}><inlineCode parentName="a">{`step ssh`}</inlineCode></a>{` command groups to interact with the CA. See `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-ca/basic-certificate-authority-operations"
      }}>{`Basic CA Operations`}</a>{` for an overview of common operations.`}</p>
    <h4>{`Accessing your CA remotely`}</h4>
    <p>{`To access your CA remotely, install and use the `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli"
      }}><inlineCode parentName="a">{`step`}</inlineCode></a>{` command on clients.
Or you can `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/tutorials/acme-protocol-acme-clients"
      }}>{`use any ACME client to get certificates`}</a>{`.`}</p>
    <p>{`Because your CA root certificate is a self-signed certificate, it is not automatically trusted by clients.
Any new `}<inlineCode parentName="p">{`step`}</inlineCode>{` client will need to establish a trust relationship with your CA.
You can do this by supplying your CA fingerprint to `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/ca/bootstrap"
      }}><inlineCode parentName="a">{`step ca bootstrap`}</inlineCode></a>{`.
Your CA fingerprint is a cryptographic signature identifying your root CA certificate.`}</p>
    <p>{`To configure `}<inlineCode parentName="p">{`step`}</inlineCode>{` to access your CA from a new machine, run:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session"
      }}>{`$ step ca bootstrap --ca-url [CA URL] --fingerprint [CA fingerprint]
The root certificate has been saved in /home/alice/.step/certs/root_ca.crt.
Your configuration has been saved in /home/alice/.step/config/defaults.json.
`}</code></pre>
    <p>{`This command will download the root CA certificate and write CA connection details to `}<inlineCode parentName="p">{`$HOME/.step/config/defaults.json`}</inlineCode>{`. The `}<inlineCode parentName="p">{`step`}</inlineCode>{` command will now trust your CA. See `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-ca/basic-certificate-authority-operations"
      }}>{`Basic CA Operations`}</a>{` for an overview of common operations.`}</p>
    <Alert severity="info" mdxType="Alert">
    <AlertTitle mdxType="AlertTitle">
      Don't know your CA fingerprint?
    </AlertTitle>
    <div>
		You can get it by running the following on your CA:
		<CodeBlock language="shell-session" copyText="step certificate fingerprint $(step path)/certs/root_ca.crt" mdxType="CodeBlock">
		$ step certificate fingerprint $(step path)/certs/root_ca.crt
		</CodeBlock>
	</div>
    </Alert>
    <p>{`You may also wish to establish system-wide trust of your CA, so your certificates will be trusted by `}<inlineCode parentName="p">{`curl`}</inlineCode>{` and other programs. Use the `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/certificate/install"
      }}><inlineCode parentName="a">{`step certificate install`}</inlineCode></a>{` command to install your root CA certificate into your system's trust store:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session"
      }}>{`$ step certificate install $(step path)/certs/root_ca.crt
Certificate /home/alice/.step/certs/root_ca.crt has been installed.
X.509v3 Root CA Certificate (ECDSA P-256) [Serial: 2282...6360]
  Subject:     Example Inc. Root CA
  Issuer:      Example Inc. Root CA
  Valid from:  2021-05-11T21:40:19Z
          to:  2031-05-09T21:40:19Z
`}</code></pre>
    <h2>{`Example: Run A Local Web Server Using TLS`}</h2>
    <p>{`Now that the certificate authority is running, let's create an example server and use our new certificate authority to issue an X.509 certificate and connect to the server using HTTP over TLS.`}</p>
    <p>{`For this example, you'll need to have `}<a parentName="p" {...{
        "href": "https://golang.org/"
      }}><inlineCode parentName="a">{`go`}</inlineCode></a>{` installed.`}</p>
    <h3>{`Get a certificate`}</h3>
    <p>{`First, let's ask the CA for a certificate (`}<inlineCode parentName="p">{`srv.crt`}</inlineCode>{`) and private key (`}<inlineCode parentName="p">{`srv.key`}</inlineCode>{`) for our example server on `}<inlineCode parentName="p">{`localhost`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ step ca certificate localhost srv.crt srv.key

✔ Key ID: rQxROEr7Kx9TNjSQBTETtsu3GKmuW9zm02dMXZ8GUEk (bob@example.com)
✔ Please enter the password to decrypt the provisioner key: abc123
✔ CA: https://localhost:8443/1.0/sign
✔ Certificate: srv.crt
✔ Private Key: srv.key
`}</code></pre>
    <h3>{`Run a hello world example server configured for TLS connections`}</h3>
    <p>{`We've got our certificate. Now let's create an example server that will run on localhost and use TLS.`}</p>
    <p>{`This example server listens to port `}<inlineCode parentName="p">{`9443`}</inlineCode>{` and serves `}<em parentName="p">{`Hello, world!`}</em>{` to any client that accepts the server certificate as trusted.`}</p>
    <p>{`Here's the code for the go server:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-go"
      }}>{`package main
import (
    "net/http"
    "log"
)
func HiHandler(w http.ResponseWriter, req *http.Request) {
    w.Header().Set("Content-Type", "text/plain")
    w.Write([]byte("Hello, world!\\n"))
}
func main() {
    http.HandleFunc("/hi", HiHandler)
    err := http.ListenAndServeTLS(":9443", "srv.crt", "srv.key", nil)
    if err != nil {
        log.Fatal(err)
    }
}
`}</code></pre>
    <p>{`Run the server as a background process:`}</p>
    <CodeBlock language="shell-session" copyText="go run srv.go &" mdxType="CodeBlock">
  {`$ go run srv.go &`}
    </CodeBlock>
    <h3>{`Connecting to the example server`}</h3>
    <p>{`First, let's try making a curl request to our example server. In a new terminal, run:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ curl https://localhost:9443/hi
`}</code></pre>
    <p>{`If you already ran `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/certificate/install"
      }}><inlineCode parentName="a">{`step certificate install`}</inlineCode></a>{` to install your root CA into your system's trust store, this command will work and you'll see "Hello, World!".`}</p>
    <p>{`If not, you'll see this error:`}</p>
    <pre><code parentName="pre" {...{}}>{`curl: (60) SSL certificate problem: unable to get local issuer certificate
More details here: https://curl.haxx.se/docs/sslcerts.html
curl failed to verify the legitimacy of the server and therefore could not
establish a secure connection to it. To learn more about this situation and
how to fix it, please visit the web page mentioned above.
`}</code></pre>
    <p>{`In this case, `}<inlineCode parentName="p">{`curl`}</inlineCode>{` does not yet trust your root CA.`}</p>
    <p>{`Let's download the CA's root certificate and pass it into `}<inlineCode parentName="p">{`curl`}</inlineCode>{` to establish trust.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ step ca root root.crt

The root certificate has been saved in root.crt.
`}</code></pre>
    <p>{`Now you can make a curl request using the root certificate you just downloaded. When you add `}<inlineCode parentName="p">{`--cacert root.crt`}</inlineCode>{` to the curl command, it will verify that the server certificate was signed by the CA, and at that point you will see `}<em parentName="p">{`Hello, world!`}</em></p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ curl --cacert root.crt https://localhost:9443/hi

Hello, world!
`}</code></pre>
    <p>{`Nice! You've just generated and integrated our first certificate using `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{`. `}</p>
    <h2>{`Next Steps`}</h2>
    <ul>
      <li parentName="ul">{`Familiarize yourself with `}<a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/step-ca/basic-certificate-authority-operations"
        }}>{`Basic CA Operations`}</a>{` using `}<inlineCode parentName="li">{`step ca`}</inlineCode>{` and `}<inlineCode parentName="li">{`step ssh`}</inlineCode>{` command groups.`}</li>
      <li parentName="ul">{`Check out the `}<a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/step-ca/configuration"
        }}>{`Configuration`}</a>{` and `}<a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/step-ca/certificate-authority-core-concepts"
        }}>{`Core Concepts`}</a>{` sections to learn more about tailoring
`}<inlineCode parentName="li">{`step-ca`}</inlineCode>{` to your infrastructure needs.`}</li>
      <li parentName="ul">{`Or, head straight to our `}<a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/tutorials"
        }}>{`tutorials`}</a>{` to see more examples of using `}<inlineCode parentName="li">{`step-ca`}</inlineCode>{` in the wild.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      